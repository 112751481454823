/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TiltNeon";
  src: url("/public/assents/font/TiltNeon-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Tahoma";
  src: url("/public/assents/font/Tahoma.ttf");
  font-weight: 400;
}
body {
  font-family: "TiltNeon", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.Tahoma {
  font-family: "Tahoma", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.container {
  @apply px-[5px] lg:px-[30px] max-w-2xl;
}

.bgMain {
  opacity: 0.9;
  padding: 8px 12px;
  color: white !important;
  @apply rounded-md;
}
.btn {
  cursor: pointer;
  border: none;
  margin: 20px 0px 0px;
  padding: 0px 12px;
  border-radius: 4px;
  -webkit-box-shadow: rgb(235, 235, 235) 0px 6px 0px;
  box-shadow: rgb(235, 235, 235) 0px 6px 0px;
  font-size: 22px;
  height: 55px;
  color: rgb(66, 122, 188);
  font-weight: bold;
  width: 200px;
  background-color: white;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.btnNew {
  background-color: hwb(0 29% 27%);
  color: white;
  -webkit-box-shadow: rgb(201, 60, 60) 0px 6px 0px;

}
.btn:hover {
  background: rgb(66, 122, 188);
  -webkit-box-shadow: rgba(66, 122, 188, 0.566) 0px 6px 0px;
  box-shadow: rgba(66, 122, 188, 0.566) 0px 6px 0px;
  color: white;
}

.transition-X {
  -webkit-transition: -webkit-transform 0.1s ease-in;
  transition: -webkit-transform 0.1s ease-in;
  -o-transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}
.btnTime {
  cursor: pointer;
  background: none rgba(0, 0, 0, 0.15);
  color: white;
}
.selectBtn button {
  border-radius: 4px;
  padding: 2px 12px;
}

.taskbtb {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  margin-top: 12px;
  border: 2px dashed rgba(255, 255, 255, 0.4);
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.taskbtb:hover {
  opacity: 1;
}
.formTask {
  background-color: white;
  border-radius: 8px;
  text-align: left;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px,
    rgba(0, 0, 0, 0.1) 0px 3px 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
}
.footerTask {
  padding: 14px 20px;
  text-align: right;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: rgb(239, 239, 239);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.taksBtn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  opacity: 0.9;
  font-size: 14px;
  padding: 8px 12px;
  min-width: 70px;

  display: inline-block;
}
.saveBtn {
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;

  background-color: rgb(34, 34, 34);
  border: 2px solid rgb(34, 34, 34);
}
.cancelBtn {
  color: rgb(136, 136, 136);
}

.inputStyle {
  border-radius: 4px;
  padding: 10px 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  color: rgb(85, 85, 85);
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: bold;
  @apply md:text-[22px];
}
.numberInput {
  border-radius: 4px;
  background-color: rgb(239, 239, 239);
  font-size: 16px;
  padding: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  color: rgb(85, 85, 85);
  width: 75px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 10px;
  font-weight: bold;
}
.arrow {
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.9;
  width: 40px;
  height: 40px;
  background-color: white;
  color: rgb(85, 85, 85);
  border: 1px solid rgb(223, 223, 223);
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  @apply flex items-center justify-center;
}
.arrow:hover {
  opacity: 1;
}

.addNote {
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.9;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0.02em;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  text-decoration: underline;
  padding: 10px 0;
}
.addNote:hover {
  opacity: 1;
}
.bSPdnR {
  border-radius: 4px;
  background-color: rgb(239, 239, 239);
  border: none;
  padding: 10px 14px;
  color: rgb(85, 85, 85);
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.4em;
}
.noteTask {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
  white-space: pre-wrap;
  color: rgb(96, 85, 21);
  padding: 10px 12px;
  word-break: break-word;
  border-radius: 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 0px;
  background-color: rgb(252, 248, 222);
}
.kZXCiQ {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 30;
  pointer-events: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.2s ease-in 0s;
  -o-transition: all 0.2s ease-in 0s;
  transition: all 0.2s ease-in 0s;
  overflow: auto;
  padding: 48px 0px 58px;
}
.eGoAxl {
  color: rgb(34, 34, 34);
  border-radius: 8px;
  background-color: white;
  position: relative;
  max-width: 400px;
  width: 95%;
  z-index: 30;
  border-top: 1px solid rgb(239, 239, 239);
  border-bottom: 1px solid rgb(239, 239, 239);
  margin: auto;
  -webkit-transition: all 0.2s ease-in 0s;
  -o-transition: all 0.2s ease-in 0s;
  transition: all 0.2s ease-in 0s;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px,
    rgba(0, 0, 0, 0.1) 0px 3px 6px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 20px, rgba(0, 0, 0, 0.1) 0px 3px 6px;
  overflow: hidden;
  display: block;
}
.selectInput {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.selectInput option {
  font-size: 1rem;
}

.selectInput::-ms-expand {
  display: none;
}

.selectInput:focus {
  @apply border-gray-200;
}
